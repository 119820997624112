import { constantRoutes } from "@/router";
// import { asyncRoutesHttp } from "../../../mock/role/routes";
import Layout from "@/layout";

/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routes (resolve: any) => require([`@/views/${view}.vue`], resolve)
 */
export function generaMenu(routes, data) {
  data.forEach((item) => {
    // 这里的字段在实际业务开发中都有用的，当然如果只是简单学习的话，id，label可以移除
    const menu = {
      path: item.path,
      component:
        item.component === "#"
          ? Layout
          : (resolve) => require([`@/views${item.component}`], resolve),
      redirect: item.redirect,
      hidden: item.hidden,
      alwaysShow: item.alwaysShow,
      meta: item.meta,
      name: item.name,
      label: item.label,
      children: [],
    };
    if (item.children) {
      generaMenu(menu.children, item.children);
    }
    routes.push(menu);
  });
  return routes;
}

const state = {
  routes: [],
  asyncRoutesHttp: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    // 拼接静态路由和动态路由
    state.routes = constantRoutes;
  },
  SET_ASYNC_ROUTES_HTTP: (state, routes) => {
    state.asyncRoutesHttp = routes;
  },
};

const actions = {
  generateRoutes({ commit }) {
    return new Promise((resolve) => {
      commit("SET_ROUTES");
      resolve();
    }).catch((error) => {
      console.log(error);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
