<template>
  <section class="app-main sidebar_clear_head">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" :windowWidth="windowWidth" />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  props: ["windowWidth"],
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  width: 100%;
  position: relative;
}
</style>
