<template>
  <div class="footer_list">
    <div v-if="windowWidth > 1282" style="display: flex">
      <div v-for="item in footer" :key="item.title" class="footer_item">
        <title-footer :title="item.title" :path="item.path" />
        <div
          v-for="itemC in item.children"
          :key="itemC.text"
          class="footer_item_text"
        >
          <router-link v-if="item.path" :to="itemC.path">
            <div>{{ itemC.text }}</div>
          </router-link>
          <div v-else>{{ itemC.text }}</div>
        </div>
      </div>
      <div class="footer_item">
        <div class="footer_right_list">
          <div class="footer_right_box">
            <title-footer title="联系我们" path="" />
            <div class="right_item">
              <div class="right_item_row">
                <img class="right_item_icon" src="@/assets/phone.png" />
                <div class="right_item_text">联系电话</div>
              </div>
              <div class="right_item_title">15112341234</div>
              <div class="right_item_row">
                <img class="right_item_icon" src="@/assets/location.png" />
                <div class="right_item_text">公司地址</div>
              </div>
            </div>
          </div>
          <div class="footer_right_box">
            <img class="footer_right_qr" src="@/assets/qr.png" />
            <div class="right_item_text footer_scan">扫一扫联系我们</div>
          </div>
        </div>
        <div class="right_item_text">
          浙江省宁波市鄞州区南部商务区科技孵化园E栋812-813室
        </div>
      </div>
    </div>
    <div class="accordion" v-else>
      <div v-for="(item, index) in items" :key="index" class="accordion_box">
        <div class="accordion_header" @click="toggle(index)">
          <span class="accordion_header_title">{{ item.title }}</span>
          <span v-if="!item.isActive" class="accordion_header_icon">+</span>
          <span v-else class="accordion_header_icon">-</span>
        </div>
        <div
          class="accordion_body"
          :class="{ accordion_body_active: activeMenu == item1.path }"
          v-show="item.isActive"
          v-for="item1 in item.children"
          :key="item1.id"
        >
          <router-link v-if="item1.path" :to="item1.path">
            <div>{{ item1.text }}</div>
          </router-link>
          <div v-else>{{ item1.text }}</div>
        </div>
      </div>
      <div class="accordion_bottom">
        <div class="accordion_bottom_box">
          <div class="accordion_bottom_title">版权所有</div>
          <div class="accordion_bottom_text">备案号</div>
        </div>
        <img src="@/assets/weixin.png" class="accordion_bottom_pic" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleFooter from "./TitleFooter";
export default {
  name: "FooterItem",
  components: { TitleFooter },
  props: {
    // route object
    footer: {
      type: Array,
      required: true,
    },
    windowWidth: {
      type: Number,
    },
  },
  data() {
    return {
      items: [
        {
          title: "案例展示",
          isActive: false,
          children: [
            {
              path: "/",
              text: "全部案例",
              isActive: true,
            },
            {
              path: "/appDevelop",
              text: "APP开发",
              isActive: false,
            },
            {
              path: "/appletsDevelop",
              text: "小程序开发",
              isActive: false,
            },
            {
              path: "/websiteDevelop",
              text: "网站建设",
              isActive: false,
            },
          ],
        },
        {
          title: "解决方案",
          isActive: false,
          children: [
            {
              path: "/",
              text: "全部案例",
              isActive: false,
            },
            {
              path: "/appDevelop",
              text: "APP开发",
              isActive: false,
            },
            {
              path: "/appletsDevelop",
              text: "小程序开发",
              isActive: false,
            },
            {
              path: "/websiteDevelop",
              text: "网站建设",
              isActive: false,
            },
          ],
        },
        {
          title: "定制服务",
          isActive: false,
          children: [
            {
              path: "/",
              text: "全部案例",
              isActive: false,
            },
            {
              path: "/appDevelop",
              text: "APP开发",
              isActive: false,
            },
            {
              path: "/appletsDevelop",
              text: "小程序开发",
              isActive: false,
            },
            {
              path: "/websiteDevelop",
              text: "网站建设",
              isActive: false,
            },
          ],
        },
        {
          title: "联系我们",
          isActive: false,
          children: [
            {
              path: "/",
              text: "全部案例",
              isActive: false,
            },
            {
              path: "/appDevelop",
              text: "APP开发",
              isActive: false,
            },
            {
              path: "/appletsDevelop",
              text: "小程序开发",
              isActive: false,
            },
            {
              path: "/websiteDevelop",
              text: "网站建设",
              isActive: false,
            },
          ],
        },
      ],
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  watch: {
    activeMenu(newValue, oldValue) {
      if (newValue) {
        this.items.forEach((item) => {
          item.isActive = false;
        });
      }
    },
  },
  methods: {
    toggle(val) {
      this.items.forEach((item, index) => {
        if (val == index) {
          item.isActive = !item.isActive;
        } else {
          item.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  margin: 0 20px;
  .accordion_box {
    border-bottom: 1px solid #ececec;
    margin-top: 10px;
    .accordion_header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      .accordion_header_title {
        font-size: 12px;
        line-height: 17px;
        color: #2c2f30;
      }
      .accordion_header_icon {
        font-size: 18px;
        color: #2c2f30;
      }
    }
    .accordion_body {
      font-size: 10px;
      font-weight: 300;
      color: #b5b5b5;
      padding: 10px 0;
    }
    .accordion_body_active {
      color: #f00808;
    }
  }
}
.accordion_bottom {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .accordion_bottom_box {
    .accordion_bottom_title,
    .accordion_bottom_text {
      font-size: 10px;
      color: #868585;
      line-height: 17px;
      margin-bottom: 17px;
    }
  }
  .accordion_bottom_pic {
    width: 23px;
    height: 19px;
  }
}
</style>
