<template>
  <div>
    <router-link v-if="path" :to="path">
      <div class="footer_item_title">{{ title }}</div>
    </router-link>
    <div v-else class="footer_item_title">{{ title }}</div>
    <div class="title_line" />
  </div>
</template>

<script>
export default {
  name: "TitleFooter",
  props: {
    title: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      require,
    },
  },
};
</script>

<style></style>
