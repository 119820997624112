import Vue from "vue";
import VueRouter from "vue-router";

import Layout from "@/layout";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/",
    children: [
      {
        path: "/",
        component: () => import("@/views/home/home"),
        name: "Home",
        meta: { title: "首页" },
      },
    ],
  },
  {
    path: "/appDevelop",
    component: Layout,
    redirect: "/appDevelop",
    children: [
      {
        path: "/appDevelop",
        component: () => import("@/views/appDevelop/appDevelop"),
        name: "AppDevelop",
        meta: { title: "APP开发" },
      },
    ],
  },
  {
    path: "/appletsDevelop",
    component: Layout,
    redirect: "/appletsDevelop",
    children: [
      {
        path: "/appletsDevelop",
        component: () => import("@/views/appletsDevelop/appletsDevelop"),
        name: "AppletsDevelop",
        meta: { title: "小程序开发" },
      },
    ],
  },
  {
    path: "/websiteDevelop",
    component: Layout,
    redirect: "/websiteDevelop",
    children: [
      {
        path: "/websiteDevelop",
        component: () => import("@/views/websiteDevelop/websiteDevelop"),
        name: "WebsiteDevelop",
        meta: { title: "网站建设" },
      },
    ],
  },
  {
    path: "/case",
    component: Layout,
    redirect: "/case",
    children: [
      {
        path: "/case",
        component: () => import("@/views/case/case"),
        name: "Case",
        meta: { title: "案例展示" },
      },
      {
        path: "/caseDetails",
        component: () => import("@/views/caseDetails/caseDetails"),
        name: "CaseDetails",
        meta: { title: "案例详情" },
        hidden: true,
      }
    ],
  },
  {
    path: "/connect",
    component: Layout,
    redirect: "/connect",
    children: [
      {
        path: "/connect",
        component: () => import("@/views/connect/connect"),
        name: "Connect",
        meta: { title: "联系我们" },
      },
    ],
  }
];

const createRouter = () => new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router;
