<template>
  <div class="app_wrapper">
    <!-- <div class="drawer-bg" /> -->
    <sidebar class="sidebar_container" :windowWidth="windowWidth" />
    <app-main :windowWidth="windowWidth" />
    <ws-footer :windowWidth="windowWidth" />
    <record />
  </div>
</template>

<script>
import { AppMain, Sidebar, WsFooter, Record } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    AppMain,
    Sidebar,
    WsFooter,
    Record,
  },
  data() {
    return {
      windowWidth: document.body.clientWidth,
    };
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
    classObj() {
      return {
        mobile: this.device === "mobile",
      };
    },
  },
  mounted() {
    var _this = this;
    window.onresize = () => {
      return (() => {
        _this.windowWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// @import "~@/styles/mixin.scss";
// @import "~@/styles/variables.scss";

.app_wrapper {
  // @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
