<template>
  <div class="ws_header">
    <div class="ws_header_box" v-if="windowWidth > 1282">
      <logo />
      <div style="flex: 1" />
      <el-menu
        :default-active="activeMenu"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="horizontal"
        class="ws_menu"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          class="el-menu-item"
        />
      </el-menu>
      <button class="ws_header_login">登录</button>
    </div>
    <div class="ws_header_box" v-else>
      <logo />
      <img
        :src="
          isExpand
            ? require('@/assets/close.png')
            : require('@/assets/expand.png')
        "
        class="ws_header_pic"
        @click="expandMenu()"
      />
    </div>
    <el-menu
      :default-active="activeMenu"
      class="ws_menu_vertical"
      :class="{ ws_menu_expand: isExpand && windowWidth <= 1282 }"
      router
    >
      <el-menu-item
        :index="item.path"
        class="ws_menu_item"
        v-for="item in menuList"
        :key="item.path"
      >
        <div class="ws_menu_box">
          <span slot="title" style="font-size: 15px">{{ item.pathName }}</span>
          <img
            src="@/assets/arrow.png"
            style="width: 8px; height: 15px"
            class="ws_menu_icon"
          />
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.module.scss";

export default {
  components: { SidebarItem, Logo },
  props: ["windowWidth"],
  data() {
    return {
      activeIndex: "1",
      isExpand: false,
      menuList: [
        {
          path: "/",
          pathName: "首页",
        },
        {
          path: "/appDevelop",
          pathName: "APP开发",
        },
        {
          path: "/appletsDevelop",
          pathName: "小程序开发",
        },
        {
          path: "/websiteDevelop",
          pathName: "网站建设",
        },
        {
          path: "/case",
          pathName: "案例展示",
        },
        {
          path: "/connect",
          pathName: "联系我们",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["permission_routes"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      console.log(variables);
      return variables;
    },
  },
  created() {
    console.log(this.permission_routes);
  },
  watch: {
    windowWidth(newValue, oldValue) {
      if (newValue > 1282) {
        this.isExpand = false;
      }
    },
    activeMenu(newValue, oldValue) {
      if (newValue) {
        this.isExpand = false;
      }
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    expandMenu() {
      this.isExpand = !this.isExpand;
    },
  },
};
</script>

<style lang="scss" scoped></style>
