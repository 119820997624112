<template>
  <div class="ws_record">
    闻释信息
    <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
      浙ICP备2022025026号-1
    </a>
    wenshixinxi.com
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ws_record {
  width: 100%;
  visibility: hidden;
  height: 39px;
  zoom: 1;
  margin: 0;
  line-height: 39px;
  background: #fff;
  visibility: visible;
  color: #bbb;
  font-size: 12px;
  text-align: center;
}
</style>
