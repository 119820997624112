<template>
  <div class="ws_footer">
    <div class="ws_footer_box">
      <item :footer="footerList" :windowWidth="windowWidth" />
    </div>
  </div>
</template>

<script>
import Item from "./Item";
export default {
  name: "WsFooter",
  components: { Item },
  props: ["windowWidth"],
  data() {
    return {
      footerList: [
        {
          path: "1",
          title: "案例展示",
          children: [
            {
              path: "1",
              text: "全部案例",
            },
            {
              path: "",
              text: "小程序开发",
            },
            {
              path: "",
              text: "APP开发",
            },
            {
              path: "",
              text: "网站建设",
            },
            {
              path: "",
              text: "H5定制",
            },
          ],
        },
        {
          path: "",
          title: "解决方案",
          children: [
            {
              path: "",
              text: "电商零售",
            },
            {
              path: "",
              text: "生活服务",
            },
            {
              path: "",
              text: "美发美妆",
            },
            {
              path: "",
              text: "教育培训",
            },
            {
              path: "",
              text: "批发市场",
            },
          ],
        },
        {
          path: "",
          title: "定制服务",
          children: [
            {
              path: "",
              text: "APP开发",
            },
            {
              path: "",
              text: "小程序开发",
            },
            {
              path: "",
              text: "网站建设",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
